
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://kw-fonts.s3.eu-west-1.amazonaws.com/BebasNeuePro/index.css);
@import url(https://kw-fonts.s3.eu-west-1.amazonaws.com/Barlow/index.css);

body {
  margin: 0;
  font-family:Barlow, 'Helvetica Neue', Helvetica, Arial, sans-serif ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.css-188mx6n-MuiSlider-root {
  color: #f4525d !important;

}
.css-cp2j25-MuiSlider-thumb{
  background-color: #fff  !important;
  border: solid 3px #e52431  !important;
}
